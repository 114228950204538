<template>
  <div>
    <v-btn
      block
      x-large
      color="primary"
      @click="submitNewOrder"
    >
      {{ $t('submit') }}
    </v-btn>
    <v-col
      cols="12"
      v-if="processing"
    >
      <template v-for="index in items.length">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </v-col>
    <v-list v-if="items !== null && processing === false">
      <draggable
        :list="items"
        handle=".drag-handle"
        :force-fallback="true"
        @end="updateSortingWeights($event)"
        chosen-class="chosen"
      >
        <template v-for="(item) in items">
          <div :key="item.uuid">
            <v-list-item>
              <v-list-item-action
                class="drag-handle"
                style="cursor: all-scroll"
              >
                <v-icon>mdi-drag</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <div 
                    contenteditable="true" 
                    @blur="updateSortingWeight(item.uuid, $event)" 
                    @keydown.enter.prevent="updateSortingWeight(item.uuid, $event); $event.target.blur()"
                    ref="indexInput"
                    class="chip-input"
                    :item="item.sorting_weight"
                  >
                    {{ item.sorting_weight }}
                  </div>

                  {{ item.title }} 

                  <v-chip v-if="item.status === false">{{ $t('inactive' )}}</v-chip>

                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="item.uuid"
            />
          </div>
        </template>
      </draggable>
    </v-list>
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    name: 'SortingList',  
    components: {
        draggable
    },
    data: () => ({
      processing: false
    }),
    computed: {
        items(){
          return this.$store.state.items
        }
    },
    methods: {
      updateSortingWeights(event) {
        const oldIndex = event.oldIndex
        const newIndex = event.newIndex

    // This is required to update the index of the item in the UI
        this.$refs.indexInput.find(ref => ref.innerText == oldIndex).innerText = newIndex

        this.items.forEach((item, index) => {
          this.$set(item, 'sorting_weight', index)
        })
      },
      updateSortingWeight(id, event) {
        const newWeight = parseInt(event.target.innerText)
        const itemIndex = this.items.findIndex(item => item.uuid === id)

        if (itemIndex !== -1) {
          const oldWeight = this.items[itemIndex].sorting_weight
          // Update the sorting_weight of the item
          this.items[itemIndex].sorting_weight = newWeight

          if (newWeight > oldWeight) {
            // If the new weight is greater than the old weight, decrement the sorting_weight of the items in between
            for (let i = oldWeight + 1; i <= newWeight; i++) {
              
              const itemsToMove = this.items.filter(item => item.sorting_weight === i && item.uuid !== id)
              itemsToMove.forEach(item => this.$set(item, 'sorting_weight', item.sorting_weight - 1))
            }
          } else if (newWeight < oldWeight) {
            // If the new weight is less than the old weight, increment the sorting_weight of the items in between
            const itemsToMove = []
            for (let i = newWeight; i < oldWeight; i++) {
              itemsToMove.push(...this.items.filter(item => item.sorting_weight === i && item.uuid !== id))
            }
            itemsToMove.forEach(item => this.$set(item, 'sorting_weight', item.sorting_weight + 1))
          }

          // Sort the items array based on the sorting_weight
          this.items.sort((a, b) => a.sorting_weight - b.sorting_weight)
        }
      },
      submitNewOrder() {
        this.processing = true
          this.$store.dispatch('updateItemOrder', this.items.map((listItem, index) => {
          return {
            uuid: listItem.uuid,
            sorting_weight: index,
            legacyId: listItem.legacyId
				  }
		    	})).then(result => {
            this.processing = false
          })

      }
    }
}
</script>

<style>
.chip-input {
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer; 
}

.chip-input input {
  border: none;
  outline: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}
.chosen {
  background-color: #c7c7c7;
}
</style>